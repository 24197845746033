import moment from "moment";

import { useSelector } from "react-redux";
import MobileCalendar from "react-scroll-calendar";
import { RootState } from "../../../Store/Reducers";
import "react-scroll-calendar/build/react-scroll-calendar.css";
const Content = ({ onClick }) => {
  const minDate = moment();
  const { date, quota } = useSelector((state: RootState) => state.journey);
  
  const getMaxDate = () => {
    const maxDate = moment().add(63, "days");

    if (quota === "TQ") {
      return moment().add(5, "days");
    } else return maxDate;
  };

  return (
    <div style={{ width: "100%", padding: "0px 15px" }}>
      <div style={{ height: "30px", width: "100%" }}></div>
      <MobileCalendar
        minDate={minDate}
        selectedDate={date === "" ? null : moment(date, "YYYYMMDD")}
        maxDate={getMaxDate()}
        enableYearTitle={false}
        onSelect={onClick}
      />
    </div>
  );
};

export default Content;
